.locale-select {
	margin-top: 5px !important;
}

.locale-select-button {
	border: none !important;
	color: white !important;
}

.locale-select-button[aria-expanded="false"]::after {
	border-top: 5px solid white !important;
}

.locale-select-button[aria-expanded="true"]::after {
	border-bottom: 5px solid white !important;
}