


.calendarDinnerTable {
    position: relative;
    width: 100%;
    height: 100%; 
    background-color:#E0E0E0;
    cursor: pointer
}

.calendarDinnerTable:hover {
background-color: #bebebe ;
}