#scSegmentedControl {
  position: relative;
  margin-left: auto;
  background-color:white;
  border-radius: 5px;
  overflow: hidden;
  z-index:5;
  width: 120px;/* 363px; */
  height: 20px;
  line-height: 20px;
  border: 1px solid rgb(0, 2, 143);
  display: flex;
}
#scBackground,
.labels {
  width: 40px;
  height: 20px;
  text-align: center;
  display: inline-block;
  padding-top: 0px;
  margin-right: 0px;
  z-index: 2;
  cursor: pointer;
  outline: 1px solid rgb(0, 2, 143);
}
#scBackground {
  background-color: rgb(227, 243, 253);
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
#sc10:checked ~ #scBackground {
  transform: translateX(0);
  transition: transform 0.25s ease-in-out;
}
#sc11:checked ~ #scBackground {
  transform: translateX(40px);
  transition: transform 0.25s ease-in-out;
}
#sc12:checked ~ #scBackground {
  transform: translateX(80px);
  transition: transform 0.25s ease-in-out;
}
#sc20:checked ~ #scBackground {
  transform: translateX(0);
  transition: transform 0.25s ease-in-out;
}
#sc21:checked ~ #scBackground {
  transform: translateX(40px);
  transition: transform 0.25s ease-in-out;
}
#sc22:checked ~ #scBackground {
  transform: translateX(80px);
  transition: transform 0.25s ease-in-out;
}
#sc30:checked ~ #scBackground {
  transform: translateX(0);
  transition: transform 0.25s ease-in-out;
}
#sc31:checked ~ #scBackground {
  transform: translateX(40px);
  transition: transform 0.25s ease-in-out;
}
#sc32:checked ~ #scBackground {
  transform: translateX(80px);
  transition: transform 0.25s ease-in-out;
}
#sc40:checked ~ #scBackground {
  transform: translateX(0);
  transition: transform 0.25s ease-in-out;
}
#sc41:checked ~ #scBackground {
  transform: translateX(40px);
  transition: transform 0.25s ease-in-out;
}
#sc42:checked ~ #scBackground {
  transform: translateX(80px);
  transition: transform 0.25s ease-in-out;
}
#sc50:checked ~ #scBackground {
  transform: translateX(0);
  transition: transform 0.25s ease-in-out;
}
#sc51:checked ~ #scBackground {
  transform: translateX(40px);
  transition: transform 0.25s ease-in-out;
}
#sc52:checked ~ #scBackground {
  transform: translateX(80px);
  transition: transform 0.25s ease-in-out;
}