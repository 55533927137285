#scSegmentedControl {
  position: relative;
  margin-left: auto;
  background-color:white;
  border-radius: 5px;
  overflow: hidden;
  z-index:5;
  width: 120px;/* 363px; */
  height: 20px;
  line-height: 20px;
  border: 1px solid rgb(0, 2, 143);
  display: flex;
}
#scBackground,
.labels {
  width: 40px;
  height: 20px;
  text-align: center;
  display: inline-block;
  padding-top: 0px;
  margin-right: 0px;
  z-index: 2;
  cursor: pointer;
  outline: 1px solid rgb(0, 2, 143);
}
#scBackground {
  background-color: rgb(227, 243, 253);
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
#sc10:checked ~ #scBackground {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
#sc11:checked ~ #scBackground {
  -webkit-transform: translateX(40px);
          transform: translateX(40px);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
#sc12:checked ~ #scBackground {
  -webkit-transform: translateX(80px);
          transform: translateX(80px);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
#sc20:checked ~ #scBackground {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
#sc21:checked ~ #scBackground {
  -webkit-transform: translateX(40px);
          transform: translateX(40px);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
#sc22:checked ~ #scBackground {
  -webkit-transform: translateX(80px);
          transform: translateX(80px);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
#sc30:checked ~ #scBackground {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
#sc31:checked ~ #scBackground {
  -webkit-transform: translateX(40px);
          transform: translateX(40px);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
#sc32:checked ~ #scBackground {
  -webkit-transform: translateX(80px);
          transform: translateX(80px);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
#sc40:checked ~ #scBackground {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
#sc41:checked ~ #scBackground {
  -webkit-transform: translateX(40px);
          transform: translateX(40px);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
#sc42:checked ~ #scBackground {
  -webkit-transform: translateX(80px);
          transform: translateX(80px);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
#sc50:checked ~ #scBackground {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
#sc51:checked ~ #scBackground {
  -webkit-transform: translateX(40px);
          transform: translateX(40px);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
#sc52:checked ~ #scBackground {
  -webkit-transform: translateX(80px);
          transform: translateX(80px);
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}
.locale-select {
	margin-top: 5px !important;
}

.locale-select-button {
	border: none !important;
	color: white !important;
}

.locale-select-button[aria-expanded="false"]::after {
	border-top: 5px solid white !important;
}

.locale-select-button[aria-expanded="true"]::after {
	border-bottom: 5px solid white !important;
}
.MuiInputBase-multiline textarea {
  resize: auto;
  min-width: 350px;
}

.chrome-picker {
  position: fixed;
  overflow: visible;
  bottom:0;
  right:0
}




.calendarDinnerTable {
    position: relative;
    width: 100%;
    height: 100%; 
    background-color:#E0E0E0;
    cursor: pointer
}

.calendarDinnerTable:hover {
background-color: #bebebe ;
}
